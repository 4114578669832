import request from '@/utils/request';

// 获取结算列表
export function getBillList(data) {
  return request({
    url: '/admin/bill/list',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取结算详情
export function getBillDetail(billId) {
  return request({
    url: `/admin/bill/details/${billId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 更改对账单状态为对账中
export function updateStatusIsSureIng(billId) {
  return request({
    url: `/admin/bill/updateStatusIsSureIng/${billId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 獲取平台修正列表
export function getBillFixByBillId(billId) {
  return request({
    url: `/admin/bill/getBillFix/${billId}`,
    baseURL: '/v2',
    method: 'get'
  });
}
