<template>
  <div class="main">
    <div class="order-infos">
      <div class="cl">
        <!--對帳單月份-->
        <div class="fl h1">{{$t('Billing month')}}：{{bill.billMonth}}</div>
        <div class="fr">
          <!--申請對賬-->
          <button class="button button-primary m-r" @click="updateIsSureIng" v-if="bill.billStatus==='NOT_CHECK'">
            {{$t('ApplicationStatement')}}
          </button>
          <!--聯繫平台-->
          <button class="button m-r" @click="contactPlatform">{{$t('ContactPlatform')}}</button>
          <!--导出excel-->
          <button class="button" @click="exportExcel">{{$t('Export excel')}}</button>
        </div>
      </div>
      <div class="infos">
        <div class="other">
          <div class="other-item">
            <!--單號-->
            <p>{{$t('Billing number')}}：{{bill.billNo}}</p>
            <!--對帳範圍-->
            <p>{{$t('StatementRange')}}：{{getRange(bill.billMonth,bill.billRange)}}</p>
            <!--結算貨幣-->
            <p>{{$t('BillingCurrency')}}：<span class="b">{{$t('Currencys')[bill.billingType]}}</span></p>
            <!--對帳單來源-->
            <p>{{$t('StatementSource')}}：{{$t("billSource")[bill.billSource]}}</p>
          </div>
          <div class="other-item">
            <!--對帳單狀態-->
            <p>{{$t('Bill status')}}：<span class="b">{{$t("BillStatus")[bill.billStatus]}}<el-button style="margin-left: 10px" type="success" plain size="mini" @click="open" v-if="bill.billStatus==='SURE_TRANSFER_OVER'">平台備註</el-button></span></p>
            <!--建立時間-->
            <p>{{$t('CreateTime')}}：{{bill.addTime|formatDate}}</p>
            <!--申請對帳時間-->
            <p>{{$t('ApplicationStatementTime')}}：{{bill.applyTime|formatDate}}</p>
            <!--完成轉帳時間-->
            <p>{{$t('CompleteTransferTime')}}：{{bill.transferTime|formatDate}}</p>
          </div>
        </div>
        <div class="bank-info">
          <!--收款銀行/機構-->
          <p>{{$t('BeneficiaryBank')}}：{{bill.bankName}}</p>
          <!--收款帳戶-->
          <p>{{$t('ReceivingAccount')}}：{{bill.bankAccounts}}</p>
          <!--收款人名稱-->
          <p>{{$t('BeneficiaryName')}}：{{bill.payee}}</p>
        </div>
      </div>
    </div>
    <div class="calculation-formula">
      <div class="fl">
        <div class="b">{{$t('OutboundOrders')}}</div>
        <div class="value">{{bill.groupMoney|numberFormat}}</div>
      </div>
      <div class="symbol">+</div>
      <!--客戶取消訂單：退款扣減-->
      <div class="fl">
        <div class="b">{{$t('CustomerCancelsOrder')}}：{{$t('TotalRefund')}}</div>
        <div class="value">{{bill.userDeductionMoney|numberFormat}}</div>
      </div>
      <div class="symbol">+</div>
      <!--商戶取消訂單：退款補償及扣減-->
      <div class="fl">
        <div class="b">{{$t('MerchantCancelOrder')}}：{{$t('RefundCompensationAndDeductions')}}</div>
        <div class="value">
          {{(bill.businessMoney*1000)/1000|numberFormat}}
        </div>
      </div>
      <div v-if="billFix.plusFix.length>0||billFix.lessFix.length>0">
        <div class="symbol">+</div>
        <!--平台修正-->
        <div class="fl">
          <div class="b">{{$t('platformModify')}}</div>
          <div class="value">
            {{(bill.fixMoney*1000)/1000|numberFormat}}
          </div>
        </div>
      </div>

      <div class="symbol">=</div>
      <div class="fl">
        <!--應收總額-->
        <div class="b">{{$t('Total amount receivable')}}</div>
        <div class="value">{{bill.totalMoney|numberFormat}}</div>
      </div>
    </div>
    <div class="divider">
      <span>{{$t('Bill details')}}</span>
    </div>
    <div class="order-detail" style="margin-top:0">
      <!--已出團訂單-->
      <div class="title">{{$t('OutboundOrders')}}</div>
      <div class="calculation-formula gray">
        <!--成交總額-->
        <div class="fl">
          <div class="b">{{$t('Total turnover')}}</div>
          <div class="value">{{add(groupDetails, 'firstAmount')|numberFormat}}</div>
        </div>
        <div class="symbol">-</div>
        <!--平台服務費-->
        <div class="fl">
          <div class="b">{{$t('Platform service fee')}}</div>
          <div class="value">{{add(groupDetails, 'secondAmount')|numberFormat}}</div>
        </div>
        <div class="symbol">=</div>
        <!--應收總額-->
        <div class="fl">
          <div class="b">{{$t('Total amount receivable')}}</div>
          <div class="value">{{add(groupDetails, 'thirdAmount')|numberFormat}}</div>
        </div>
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Order number')}}</th>
            <th>{{$t('Trading time')}}</th>
            <th>{{$t('ProductNumber')}}</th>
            <th>{{$t('ProductName')}}</th>
            <th>{{$t('Total turnover')}}</th>
            <th>{{$t('Platform service fee')}}</th>
            <th>{{$t('Total amount receivable')}}</th>
          </tr>
          </thead>
          <tbody class="tbody">
          <template v-for="item of groupDetails">
            <tr :key="item.id">
              <td>{{item.orderNo}}</td>
              <td>{{item.paymentTime|formatDate}}</td>
              <td>{{item.productNo}}</td>
              <td v-html="item.productTitle">
                <!--<div class="name">日本東京三天遊</div>
                <div class="group-num">（成團人數：5）</div>-->
              </td>
              <td>{{item.firstAmount|numberFormat}}</td>
              <td>{{item.secondAmount|numberFormat}}</td>
              <td>{{item.thirdAmount|numberFormat}}</td>
            </tr>
          </template>
          </tbody>
          <tfoot>
          <tr>
            <td class="b" colspan="4">{{$t('Total amount receivable')}}</td>
            <td class="b">{{add(groupDetails, 'firstAmount')|numberFormat}}</td>
            <td class="b">{{add(groupDetails, 'secondAmount')|numberFormat}}</td>
            <td class="b total-price">{{add(groupDetails, 'thirdAmount')|numberFormat}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="order-detail">
      <!--客戶取消訂單：扣減總額-->
      <div class="title">{{$t('CustomerCancelsOrder')}}：{{$t('TotalRefund')}}</div>
      <div class="calculation-formula gray">
        <!--成交總額-->
        <div class="fl">
          <div class="b">{{$t('Total turnover')}}</div>
          <div class="value">{{add(userDetails, 'firstAmount')|numberFormat}}</div>
        </div>
        <div class="symbol">-</div>
        <!--退款總額-->
        <div class="fl">
          <div class="b">{{$t('Total refund')}}</div>
          <div class="value">{{add(userDetails, 'secondAmount')|numberFormat}}</div>
        </div>
        <div class="symbol">=</div>
        <!--應收總額-->
        <div class="fl">
          <div class="b">{{$t('Total amount receivable')}}</div>
          <div class="value">{{add(userDetails, 'thirdAmount')|numberFormat}}</div>
        </div>
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Order number')}}</th>
            <th>{{$t('Trading time')}}</th>
            <th>{{$t('ProductNumber')}}</th>
            <th>{{$t('ProductName')}}</th>
            <th>{{$t('Total turnover')}}</th>
            <th>{{$t('Total refund')}}</th>
            <th>{{$t('Total amount receivable')}}</th>
          </tr>
          </thead>
          <tbody class="tbody">
          <template v-for="item of userDetails">
            <tr :key="item.id">
              <td>{{item.orderNo}}</td>
              <td>{{item.paymentTime|formatDate}}</td>
              <td>{{item.productNo}}</td>
              <td v-html="item.productTitle">
                <!--<div class="name">日本東京三天遊</div>
                <div class="group-num">（成團人數：5）</div>-->
              </td>
              <td>{{item.firstAmount|numberFormat}}</td>
              <td>{{item.secondAmount|numberFormat}}</td>
              <td>{{item.thirdAmount|numberFormat}}</td>
            </tr>
          </template>
          </tbody>
          <tfoot>
          <tr>
            <td class="b" colspan="4">{{$t('Total amount receivable')}}</td>
            <td class="b">{{add(userDetails, 'firstAmount')|numberFormat}}</td>
            <td class="b">{{add(userDetails, 'secondAmount')|numberFormat}}</td>
            <td class="b total-price">{{add(userDetails, 'thirdAmount')|numberFormat}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="order-detail">
      <!--商戶取消訂單：退款補償及扣減-->
      <div class="title">{{$t('MerchantCancelOrder')}}：{{$t('RefundCompensationAndDeductions')}}</div>
      <div class="calculation-formula gray">
        <!--退款扣減-->
        <div class="fl">
          <div class="b">{{$t('RefundDeduction')}}</div>
          <div class="value">{{add(businessDetails,'firstAmount')|numberFormat}}</div>
        </div>
        <div class="symbol">-</div>
        <!--退款補償-->
        <div class="fl">
          <div class="b">{{$t('RefundCompensation')}}</div>
          <div class="value">{{add(businessDetails,'secondAmount')|numberFormat}}</div>
        </div>
        <div class="symbol">=</div>
        <!--應收總額-->
        <div class="fl">
          <div class="b">{{$t('Total amount receivable')}}</div>
          <div class="value">{{add(businessDetails,'thirdAmount')|numberFormat}}</div>
        </div>
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Order number')}}</th>
            <th>{{$t('Trading time')}}</th>
            <th>{{$t('ProductNumber')}}</th>
            <th>{{$t('ProductName')}}</th>
            <th>{{$t('RefundDeduction')}}</th>
            <th>(-){{$t('RefundCompensation')}}</th>
            <th>{{$t('Total amount receivable')}}</th>
          </tr>
          </thead>
          <tbody class="tbody">
          <template v-for="item of businessDetails">
            <tr :key="item.id">
              <td>{{item.orderNo}}</td>
              <td>{{item.paymentTime|formatDate}}</td>
              <td>{{item.productNo}}</td>
              <td v-html="item.productTitle">
                <!--<div class="name">日本東京三天遊</div>
                <div class="group-num">（成團人數：5）</div>-->
              </td>
              <td>{{item.firstAmount|numberFormat}}</td>
              <td>{{item.secondAmount|numberFormat}}</td>
              <td>{{item.thirdAmount|numberFormat}}</td>
            </tr>
          </template>
          </tbody>
          <tfoot>

          <tr>
            <td class="b" colspan="4">{{$t('Total amount receivable')}}</td>
            <td class="b">{{add(businessDetails,'firstAmount')|numberFormat}}</td>
            <td class="b">{{add(businessDetails,'secondAmount')|numberFormat}}</td>
            <td class="b total-price">{{add(businessDetails,'thirdAmount')|numberFormat}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="order-detail" v-if="billFix.plusFix.length>0||billFix.lessFix.length>0">
      <!--平台修正-->
      <div class="title">{{$t('platformModify')}}</div>
      <div class="calculation-formula gray">
        <!--補償總額-->
        <div class="fl">
          <div class="b">{{('補償總額')}}</div>
          <div class="value">{{add(billFix.plusFix,'total')|numberFormat}}</div>
        </div>
        <div class="symbol">-</div>
        <!--扣減總額-->
        <div class="fl">
          <div class="b">{{('扣減總額')}}</div>
          <div class="value">{{add(billFix.lessFix,'total')|numberFormat}}</div>
        </div>
        <div class="symbol">=</div>
        <!--平台修正金額-->
        <div class="fl">
          <div class="b">{{('平台修正金額')}}</div>
          <div class="value">{{ (add(billFix.plusFix,'total')-add(billFix.lessFix,'total'))|numberFormat}}</div>
        </div>
      </div>
      <!--補償-->
      <div class="divider">
        <span>{{('補償')}}</span>
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{('序號')}}</th>
            <th>{{('修正部分')}}</th>
            <th>{{$t('Order number')}}</th>
            <th>{{('修正內容')}}</th>
            <th>{{('金額')}}</th>
            <th>{{('數量')}}</th>
            <th>{{('總額')}}</th>
          </tr>
          </thead>
          <tbody class="tbody">
          <template v-for="(item,index) of billFix.plusFix">
            <tr :key="item.id">
              <td>{{index+1}}</td>
              <td>{{$t("orderType")[item.orderType]}}</td>
              <td>{{item.orderNo}}</td>
              <td>{{item.updateContent}}</td>
              <td>{{item.money|numberFormat}}</td>
              <td>{{item.number}}</td>
              <td>{{item.total|numberFormat}}</td>
            </tr>
          </template>
          </tbody>
          <tfoot>
          <tr>
            <td class="b" colspan="6">{{$t('Total amount receivable')}}</td>
<!--            <td class="b">{{add(billFix.plusFix,'money')|numberFormat}}</td>-->
<!--            <td class="b">{{add(billFix.plusFix,'number')|numberFormat}}</td>-->
            <td class="b total-price">{{add(billFix.plusFix,'total')|numberFormat}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
      <!--扣減-->
      <div class="divider">
        <span>{{('扣減')}}</span>
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{('序號')}}</th>
            <th>{{('修正部分')}}</th>
            <th>{{$t('Order number')}}</th>
            <th>{{('修正內容')}}</th>
            <th>{{('金額')}}</th>
            <th>{{('數量')}}</th>
            <th>{{('總額')}}</th>
          </tr>
          </thead>
          <tbody class="tbody">
          <template v-for="(item,index) of billFix.lessFix">
            <tr :key="item.id">
              <td>{{index+1}}</td>
              <td>{{$t("orderType")[item.orderType]}}</td>
              <td>{{item.orderNo}}</td>
              <td>{{item.updateContent}}</td>
              <td>{{item.money|numberFormat}}</td>
              <td>{{item.number}}</td>
              <td>{{item.total|numberFormat}}</td>
            </tr>
          </template>
          </tbody>
          <tfoot>
          <tr>
            <td class="b" colspan="6">{{$t('Total amount receivable')}}</td>
<!--            <td class="b">{{add(billFix.lessFix,'money')|numberFormat}}</td>-->
<!--            <td class="b">{{add(billFix.lessFix,'number')|numberFormat}}</td>-->
            <td class="b total-price">{{add(billFix.lessFix,'total')|numberFormat}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import {getBillDetail, updateStatusIsSureIng, getBillFixByBillId} from '../../../api/admin-settlement';
  import dateUtil from '../../../mixins/dateUtil';
  import {formatDate} from '@/utils';
  import i18n from '@/i18n';

  export default {
    name: 'AdminSettlementDetail',
    mixins: [dateUtil],
    data() {
      return {
        billId: '',
        bill: {},
        groupDetails: [],
        userDetails: [],
        businessDetails: [],
        billFix: {
          plusFix: [],
          lessFix: []
        },
        checkMemo: ''
      };
    },
    computed: {
      groupTotal() {
        return this.groupDetails.find(item => item.detailsCategory === 'total') || {};
      },
      userTotal() {
        return this.userDetails.find(item => item.detailsCategory === 'total') || {};
      },
      businessTotal() {
        return this.businessDetails.find(item => item.detailsCategory === 'total') || {};
      }
    },
    created() {
      this.billId = this.$route.params.id;
      this.getDetail();
      this.getBillFix();
    },
    methods: {
      // 获取详情
      getDetail() {
        const loading = this.$loading({text: 'Loading'});
        getBillDetail(this.billId).then(res => {
          const value = res.value || {};
          this.bill = value.bill || {};
          this.groupDetails = value.groupDetails || [];
          console.log(this.groupDetails);
          this.userDetails = value.userDetails || [];
          this.businessDetails = value.businessDetails || [];
          loading.close();
        }).catch(() => {
          loading.close();
        });
      },
      updateIsSureIng() {
        updateStatusIsSureIng(this.billId).then(res => {
          const value = res.value;
          if (value > 0) {
            this.$message.success('申请成功！');
            this.getDetail();
          } else {
            this.$message.warning('申请失败！');
          }
        });
      },
      // 獲取平台修正情況
      getBillFix() {
        const loading = this.$loading({text: 'Loading'});
        getBillFixByBillId(this.billId).then(res => {
          const value = res.value || {};
          this.billFix.plusFix = value.plusFix;
          this.billFix.lessFix = value.lessFix;
          loading.close();
        }).catch(() => {
          loading.close();
        });
      },
      // 求和
      add(arr, type) {
        let obj = {};
        arr.map(value => {
          Object.keys(value).map(key => {
            if (key === type) {
              if (obj[key]) {
                obj[key] += value[key];
              } else {
                obj[key] = value[key];
              }
            }
          });
        });
        if (JSON.stringify(obj) === '{}') {
          return 0;
        }
        return obj[type];
      },
      getRange(yearAndMonth, type) {
        if (type === '1') {
          return '1日-14日';
        } else if (type === '2') {
          const arr = yearAndMonth.split('-');
          let year = arr[0];
          let month = arr[1];
          let days = dateUtil.methods.getDays(year, month);
          return '15日-' + days + '日';
        }
      },
      open() {
        this.$alert(this.bill.checkMemo, '備註', {
          confirmButtonText: '確定'
        });
      },
      // 導出excel
      exportExcel() {
        require.ensure([], () => {
          const {exportJsonToExcel2} = require('../../../utils/Export2Excel');
          // 有多少個sheet
          var th = ['已出團訂單', '客戶取消訂單', '商戶取消訂單'];

          // 已出團訂單 表頭
          var groupHeader = ['訂單編號', '交易時間', '產品編號', '產品名稱', '成交額', '平台服務費', '應收總額'];
          var userHeader = ['訂單編號', '交易時間', '產品編號', '產品名稱', '成交額', '退款總額', '應收總額'];
          var businessHeader = ['訂單編號', '交易時間', '產品編號', '產品名稱', '退款扣減', '（-）退款補償', '應收總額'];
          var groupVal = ['orderNo', 'paymentTime', 'productNo', 'productTitle', 'firstAmount', 'secondAmount', 'thirdAmount'];

          var groupAll = ['應收總額', '', '', '', this.add(this.groupDetails, 'firstAmount'), this.add(this.groupDetails, 'secondAmount'), this.add(this.groupDetails, 'thirdAmount')];
          var userAll = ['應收總額', '', '', '', this.add(this.userDetails, 'firstAmount'), this.add(this.userDetails, 'secondAmount'), this.add(this.userDetails, 'thirdAmount')];
          var businessAll = ['應收總額', '', '', '', this.add(this.businessDetails, 'firstAmount'), this.add(this.businessDetails, 'secondAmount'), this.add(this.businessDetails, 'thirdAmount')];
          // excel文件名
          var filename = this.bill.billMonth + '月' + this.getRange(this.bill.billMonth, this.bill.billRange) + '對帳單';

          var groupList = this.formatJson(groupVal, this.groupDetails);
          groupList.unshift(groupHeader);
          groupList.push(groupAll);
          var userList = this.formatJson(groupVal, this.userDetails);
          userList.unshift(userHeader);
          userList.push(userAll);
          var businessList = this.formatJson(groupVal, this.businessDetails);
          businessList.unshift(businessHeader);
          businessList.push(businessAll);

          const dataArray = [];
          dataArray.push(groupList);
          dataArray.push(userList);
          dataArray.push(businessList);

          if (this.billFix.plusFix.length > 0 || this.billFix.lessFix.length > 0) {
            th = ['已出團訂單', '客戶取消訂單', '商戶取消訂單', '平台修正'];
            var fixHeader = ['修正部分', '訂單編號', '修正內容', '金額', '數量', '總額'];
            var fixVal = ['orderType', 'orderNo', 'updateContent', 'money', 'number', 'total'];
            var plusAll = ['應收總額', '', '', '', '', this.add(this.billFix.plusFix, 'total')];
            var lessAll = ['應收總額', '', '', '', '', this.add(this.billFix.lessFix, 'total')];

            var plusList = this.formatJson(fixVal, this.billFix.plusFix);
            plusList.unshift(fixHeader);
            plusList.unshift(['補償']);
            plusList.push(plusAll);
            var lessList = this.formatJson(fixVal, this.billFix.lessFix);
            lessList.unshift(fixHeader);
            lessList.unshift(['扣減']);
            lessList.push(lessAll);
            // 存平台修正訂單
            var fixLixt = [];
            fixLixt = fixLixt.concat(plusList, lessList);
            dataArray.push(fixLixt);
          }

          exportJsonToExcel2(th, dataArray, filename, th);
        });
      },
      // 過濾
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {
            var a = v[j];
            if (j === 'paymentTime') {
              a = formatDate(a, 'yyyy.MM.dd hh:mm');
            }
            if (j === 'orderType') {
              a = i18n.t('orderType')[a];
            }
            return a;
          }
        ));
      },
      // 聯繫平台彈框
      contactPlatform() {
        this.$alert('' +
          '<p>查詢電話：(852) 2616 0676<br>' +
          '電郵：info@unutravel.com</p>',
          '聯繫平台', {
          dangerouslyUseHTMLString: true
        });
      }
  }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .table.border{
      margin-top:16px;padding-bottom:0;overflow: auto;
      .name{max-width:180px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
      .group-num{font-size:12px;}
    }
    .tbody{
      td{vertical-align:top;}
    }
    tfoot{box-shadow:0 -4px 4px #fbfbfb;}
    .divider{
      position:relative;margin:28px 0;border-top:1px dashed #ccc;
      > span{position:absolute;top:-11px;left:50%;padding:0 30px;background-color:#fff;transform:translateX(-50%);}
    }
    .total-price{color:#ff6f61;}
  }
  .order-infos{
    .h1{line-height:40px;font-size:24px;font-weight:bold;}
    .infos{display:flex;align-items:center;margin-top:40px;padding:24px;background-color:#fbe4e1;background-image:linear-gradient(45deg, #fdf7ee 0%, #fbe4e1 100%);}
    .other{flex:1;display:flex;align-items:center;}
    .other-item{flex:1;padding-right:50px;}
    .bank-info{padding-left:50px;border-left:1px dashed #ff6f61;}
    p{line-height:30px;}
  }
  .order-detail{
    margin-top:40px;
    .title{
      position:relative;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
  }
  .calculation-formula{
    padding:24px;margin-top:16px;overflow:hidden;text-align:center;background-color:#fbe4e1;background-image:linear-gradient(45deg, #fdf7ee 0%, #fbe4e1 100%);
    &.gray{background:#f4f4f4;}
    .value{margin-top:16px;color:#ff6f61;font-size:16px;font-weight:bold;}
    .symbol{float:left;margin:0 48px;font-weight:bold;}
  }
</style>
